import React from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

class SubscribeBox extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            successVisible: false,
            failureVisible: false
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.triggerSusbcriptionState = this.triggerSusbcriptionState.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();

        trackCustomEvent({
            category: 'SubscribeBox',
            action: 'SubmittedEmail',
        });

        addToMailchimp(this.state.email)
            .then(data => {
                this.triggerSusbcriptionState(true);
                trackCustomEvent({
                    category: 'SubscribeBox',
                    action: 'EmailRegistration',
                    label: true
                });
            })
            .catch(error => {
                this.triggerSusbcriptionState(false);
                trackCustomEvent({
                    category: 'SubscribeBox',
                    action: 'EmailRegistration',
                    label: false
                });
            })
    }

    triggerSusbcriptionState(success) {
        this.setState({
            successVisible: success,
            failureVisible: !success
        })
    }

    render() {
        return (

            <div className="mb4">
                <form className="mw7 center" onSubmit={this.handleSubmit}>
                    <fieldset className="cf bn ma0 pv1">
                        <div className="cf">
                            <label className="clip" htmlFor="email-address">Email Address</label>
                            <input
                                className="f6 f5-l input-reset fl black-80 pa3 w-100 w-75-m w-80-l ba b--black-10"
                                id="email-address"
                                placeholder="Your Email Address"
                                type="text"
                                name="email-address"
                                value={this.state.email}
                                onChange={e => this.setState({ email: e.target.value })} />
                            <input
                                className="f6 f5-l button-reset fl pv3 tc bn bg-animate hover-bg-black white pointer w-100 w-25-m w-20-l"
                                style={{ backgroundColor: "#3D7CFF" }}
                                type="submit"
                                value="Subscribe" />
                            {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
                            <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
                                <input type="text" name="b_344db4b0a9b211aeb19c3b253_ff0bf2008a" tabIndex="-1" defaultValue="" />
                            </div>
                        </div>
                    </fieldset>
                    {this.state.successVisible &&
                        <span className="badge badge-pill badge-success badge-visible">You are now subscribed. Thank you!</span>
                    }
                    {this.state.failureVisible &&
                        <span className="badge badge-pill badge-warning">Failed to subscribe. Please try again later.</span>
                    }
                </form>
            </div>
        );
    }
}

export default SubscribeBox;